import { useState, useEffect } from 'react'
import Header from '../insightui/Header'
import Footer from '../insightui/Footer'
import axios from 'axios'
import { store } from '../store'
import { config } from '../models/config'
import { Row, Col } from 'react-flexbox-grid'
import { useHistory, withRouter } from 'react-router-dom'
import PortfoliosDetails from '../components/Portfolios/views/PortfoliosDetails'
import Button from '../insightui/Button'
import LoadingScreen from '../insightui/LoadingScreen'
import { Plus } from '@styled-icons/bootstrap/Plus'
import { Cancel } from '@styled-icons/material/Cancel'
import { Delete } from '@styled-icons/fluentui-system-regular/Delete'
import searchIcon from '../images_export/search-light.svg'
import DropdownMenu, { MenuItem } from '../insightui/DropdownMenu'
import { isMobile, isTablet } from 'react-device-detect'
import CreatePortfolio from '../components/Portfolios/CreatePortfolio'
import PortfolioCard from '../components/Portfolios/PortfolioCard'
import ComparePortfolios from '../components/Portfolios/views/ComparePortfolios'
import Table from '../insightui/Table'
import { CircleFill } from '@styled-icons/bootstrap/CircleFill'
import ImpactScore from '../insightui/ImpactScore'
import { useSelector } from 'react-redux'
import Confirm from '../utils/components/Confirm'

const sortMethods = {
    'Alphabetical Ascending': (x, y) => {
        if (x.name.toLowerCase() < y.name.toLowerCase()) {
            return -1
        }
        if (x.name.toLowerCase() > y.name.toLowerCase()) {
            return 1
        }
        return 0
    },
    'Alphabetical Descending': (x, y) => {
        if (x.name.toLowerCase() > y.name.toLowerCase()) {
            return -1
        }
        if (x.name.toLowerCase() < y.name.toLowerCase()) {
            return 1
        }
        return 0
    },
    'Impact Score Ascending': (x, y) => {
        if (x.impactScore < y.impactScore) {
            return -1
        }
        if (x.impactScore > y.name.impactScore) {
            return 1
        }
        return 0
    },
    'Impact Score Descending': (x, y) => {
        if (x.impactScore > y.impactScore) {
            return -1
        }
        if (x.impactScore < y.name.impactScore) {
            return 1
        }
        return 0
    },
    'Monetary Value Ascending': (x, y) => {
        if (x.monetaryValue > y.monetaryValue) {
            return -1
        }
        if (x.monetaryValue < y.monetaryValue) {
            return 1
        }
        return 0
    },
    'Monetary Value Descending': (x, y) => {
        if (x.monetaryValue < y.monetaryValue) {
            return -1
        }
        if (x.monetaryValue > y.monetaryValue) {
            return 1
        }
        return 0
    },
}

const portfolioTable = (selectedPortfolios, setSelectedPortfolios) => [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        format: (e) => (
            <a
                style={{
                    color: '#2d8aaf',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}
            >
                {e}
            </a>
        ),
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Monetary Value',
        dataIndex: 'monetaryValue',
        key: 'monetaryValue',
        format: (item) =>
            '$' +
            parseFloat(item)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
    {
        title: 'Holdings',
        dataIndex: 'totalHoldings',
        key: 'totalHoldings',
    },
    {
        title: 'Impact Score',
        dataIndex: 'impactScore',
        key: 'impactScore',
        format: (item) => (
            <ImpactScore
                style={{ paddingLeft: isMobile ? 0 : 100 }}
                score={item}
                type="small"
            />
        ),
    },
    {
        title: 'Selected',
        dataIndex: 'id',
        key: 'id',
        format: (id) => (
            <input
                type="checkbox"
                checked={Boolean(selectedPortfolios[id])}
                onClick={(e) => e.stopPropagation()}
                onChange={() => {
                    setSelectedPortfolios({
                        ...selectedPortfolios,
                        [id]: !selectedPortfolios[id],
                    })
                }}
            />
        ),
    },
]

const deletePortfolio = async (portfolioId, token) => {
    await axios.delete(`${config.api}/api/portfolios/${portfolioId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

const deletePortfolios = async (portfolios, token) => {
    for (const [portfolio, selected] of Object.entries(portfolios)) {
        if (selected) {
            await deletePortfolio(portfolio, token)
        }
    }
    window.location.reload()
}

function Portfolios(props) {
    const [ready, setReady] = useState(false)
    const [portfolios, setPortfolios] = useState([])
    const [selectedPortfolios, setSelectedPortfolios] = useState({})
    const [deleteRequested, setDeleteRequested] = useState(false)
    const [portfolioId, setPortfolioId] = useState(null)
    const [activeModal, setActiveModal] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [sortMethod, setSortMethod] = useState('Alphabetical Ascending')
    const [comparisonPortfolio, setComparisonPortfolio] = useState(null)
    const [portfolio, setPortfolio] = useState(null)
    const [tableMode, setTableMode] = useState(false)
    const history = useHistory()
    const token = useSelector((state) => state.token)

    useEffect(() => {
        if (props.match.params.id) {
            setPortfolioId(props.match.params.id)
        } else {
            if (!ready) {
                fetchAllPortfolios()
            }
        }
    })

    function setComparisonPortfolioData(id, portfolioBase, portfolioList) {
        portfolioList.forEach((portfolio) => {
            if (portfolio.id.toString() === id) {
                setComparisonPortfolio(portfolio)
                setPortfolio(portfolioBase)
            }
        })
    }

    function fetchAllPortfolios() {
        axios
            .get(`${config.api}/api/portfolios`, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response) => {
                setPortfolios(response.data.portfolios)
                setSelectedPortfolios({})
                setReady(true)
            })
    }

    function conditionalSort(portfolioList) {
        if (sortMethod === 'search') {
            return portfolioList.filter((portfolio) =>
                portfolio.name
                    .toLowerCase()
                    .includes(searchString.toLowerCase())
            )
        } else return portfolioList.sort(sortMethods[sortMethod])
    }

    function handleRedirect(portfolioData) {
        portfolios.forEach((portfolio) => {
            if (portfolioData[0] === portfolio.name) {
                history.push(`/portfolios/${portfolio.id}`)
            }
        })
    }

    return (
        <div style={{ backgroundColor: '#f7f7f7' }}>
            <div style={{ backgroundColor: '#f7f7f7', height: 1000 }}>
                {ready && !portfolioId ? (
                    <>
                        <Header logo />
                        <CreatePortfolio
                            activeModal={activeModal}
                            onChange={setActiveModal}
                            onComplete={fetchAllPortfolios}
                        />
                        <Confirm
                            message="Are you sure you want to delete the selected portfolios?"
                            onClose={() => setDeleteRequested(false)}
                            onConfirm={() =>
                                deletePortfolios(selectedPortfolios, token)
                            }
                            isActive={deleteRequested}
                        />
                        <div
                            style={{
                                padding: '110px 20px 20px',
                            }}
                        >
                            <Row>
                                <Col>
                                    <h1 style={{ fontSize: 54 }}>Portfolios</h1>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 40 }}>
                                <Col
                                    style={{
                                        position: 'relative',
                                        marginBottom: 15,
                                    }}
                                    xl={4}
                                    md={6}
                                    sm={12}
                                >
                                    <input
                                        style={{
                                            border: 0,
                                            width: '92%',
                                            backgroundColor: 'white',
                                            padding: 14,
                                            paddingLeft: 22,
                                            paddingRight: 0,
                                            paddingBottom: 13,
                                            color: '#0b192c',
                                            fontWeight: 900,
                                            fontSize: 16,
                                            boxShadow: '2px 2px 4px #eeeeef',
                                            borderRadius: 4,
                                            opacity: tableMode ? 0.5 : '',
                                        }}
                                        disabled={tableMode}
                                        placeholder="Search Portfolios"
                                        value={searchString}
                                        onChange={(e) => {
                                            setSortMethod('search')
                                            setSearchString(e.target.value)
                                        }}
                                    />
                                    {searchString !== '' ? (
                                        <Cancel
                                            width={20}
                                            color="grey"
                                            onClick={() => setSearchString('')}
                                            style={{
                                                position: 'absolute',
                                                right: '8%',
                                                top: 13,
                                                cursor: 'pointer',
                                            }}
                                        />
                                    ) : (
                                        <img
                                            style={{
                                                position: 'absolute',
                                                top: 13,
                                                right: '8%',
                                                cursor: tableMode
                                                    ? ''
                                                    : 'pointer',
                                                opacity: tableMode ? 0.5 : '',
                                            }}
                                            src={searchIcon}
                                            width={18}
                                            alt="search"
                                        />
                                    )}
                                </Col>
                                <Col
                                    xl={4}
                                    md={6}
                                    xs={12}
                                    style={{
                                        marginBottom: 15,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: 10,
                                        }}
                                    >
                                        <DropdownMenu
                                            style={{
                                                opacity: tableMode ? 0.5 : '',
                                                pointerEvents: tableMode
                                                    ? 'none'
                                                    : '',
                                                marginBottom: 15,
                                                display: 'inline-block',
                                            }}
                                            value={sortMethod}
                                            setSelection={(e) => {
                                                setSortMethod(e)
                                                setSearchString('')
                                            }}
                                            width={245}
                                        >
                                            <MenuItem value="Alphabetical Ascending" />
                                            <MenuItem value="Alphabetical Descending" />
                                            <MenuItem value="Impact Score Ascending" />
                                            <MenuItem value="Impact Score Descending" />
                                            <MenuItem value="Monetary Value Ascending" />
                                            <MenuItem value="Monetary Value Descending" />
                                        </DropdownMenu>
                                        <div
                                            style={{
                                                padding: 7,
                                                paddingLeft: 13,
                                                marginLeft: 10,
                                                paddingRight: 13,
                                                height: 20,
                                                position: 'relative',
                                                display: 'inline-block',
                                                bottom: 13,
                                                width: 68,
                                                color: 'white',
                                                fontWeight: 600,
                                                fontSize: 14,
                                                borderRadius: 30,
                                                userSelect: 'none',
                                                backgroundColor: tableMode
                                                    ? '#d17930'
                                                    : '#2c8aae',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                setTableMode(!tableMode)
                                            }
                                        >
                                            <strong>
                                                {tableMode ? (
                                                    <>
                                                        Table
                                                        <CircleFill
                                                            width={20}
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <CircleFill
                                                            width={20}
                                                        />
                                                        <span
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                        >
                                                            Grid
                                                        </span>
                                                    </>
                                                )}
                                            </strong>
                                        </div>
                                    </div>
                                </Col>

                                <Col
                                    sm={6}
                                    xl={2}
                                    style={{
                                        textAlign:
                                            isMobile && !isTablet
                                                ? 'left'
                                                : 'right',
                                    }}
                                >
                                    <div>
                                        <Button
                                            type="elastic"
                                            onClick={() =>
                                                setDeleteRequested(true)
                                            }
                                            style={{
                                                display: 'inline-block',
                                                width:
                                                    isMobile && !isTablet
                                                        ? '95%'
                                                        : 'auto',
                                            }}
                                        >
                                            <Delete width={20} />
                                            Delete Selected
                                        </Button>
                                    </div>
                                </Col>

                                <Col
                                    sm={6}
                                    xl={2}
                                    style={{
                                        textAlign:
                                            isMobile && !isTablet
                                                ? 'left'
                                                : 'right',
                                    }}
                                >
                                    <div>
                                        <Button
                                            type="elastic"
                                            onClick={() => setActiveModal(true)}
                                            style={{
                                                display: 'inline-block',
                                                width:
                                                    isMobile && !isTablet
                                                        ? '95%'
                                                        : 'auto',
                                            }}
                                        >
                                            <Plus width={20} />
                                            Create Portfolio
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            {tableMode ? (
                                <Row>
                                    <Table
                                        value={'Portfolios'}
                                        headers={portfolioTable(
                                            selectedPortfolios,
                                            setSelectedPortfolios
                                        )}
                                        dataSource={conditionalSort(portfolios)}
                                        onClick={handleRedirect}
                                    />
                                </Row>
                            ) : (
                                <Row>
                                    {conditionalSort(portfolios).map(
                                        (portfolio) => (
                                            <PortfolioCard
                                                portfolio={portfolio}
                                                selected={Boolean(
                                                    selectedPortfolios[
                                                        portfolio.id
                                                    ]
                                                )}
                                                setSelected={(selected) =>
                                                    setSelectedPortfolios({
                                                        ...selectedPortfolios,
                                                        [portfolio.id]:
                                                            selected,
                                                    })
                                                }
                                            />
                                        )
                                    )}
                                </Row>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        {!portfolioId ? (
                            <LoadingScreen defaultMessage="Loading Portfolios" />
                        ) : (
                            <></>
                        )}
                    </>
                )}
                {portfolioId && portfolios && !comparisonPortfolio ? (
                    <>
                        <PortfoliosDetails
                            portfolioId={portfolioId}
                            portfolios={portfolios}
                            onCompare={setComparisonPortfolioData}
                        />
                    </>
                ) : (
                    <></>
                )}

                {comparisonPortfolio ? (
                    <ComparePortfolios
                        base={portfolio}
                        compare={comparisonPortfolio}
                        portfolios={portfolios}
                    />
                ) : (
                    <></>
                )}
                <Footer />
            </div>
        </div>
    )
}

export default withRouter(Portfolios)
